<template>
  <div class="pagination">
    <div style="font-size: 15px; margin-bottom: 20px; margin-top: -10px">
  
      <span
        >共<i>{{ allPage }}</i
        >页</span
      >
    </div>
    <ul v-if="allPage > 1">
      <li v-if="currentPage > 1">
        <span @click="prevPage">上一页</span>
      </li>
      <li v-if="currentPage == 1">
        <span class="disabled">上一页</span>
      </li>
      <li
        v-for="item in indexs"
        :key="item"
        v-bind:class="{ active: currentPage == item }"
        class="item"
      >
        <span @click="btnClick(item)">{{ item }}</span>
      </li>
      <li v-if="currentPage != allPage">
        <span @click="nextPage">下一页</span>
      </li>
      <li v-if="currentPage == allPage">
        <span class="disabled">下一页</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    currentPage: {
      // 当前第几页
      type: Number,
      default: 1,
    },
    total: {
      // 总页数
      type: Number,
      default: 5,
    },
    pageSize: {
      type: Number,
      default: 15,
    },
    showIndex: {
      // 中间显示几个页数，要为奇数
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      allPage: 0,
    };
  },
  methods: {
    nextPage() {
      this.$emit("setPage", this.currentPage + 1);
    },
    prevPage() {
      this.$emit("setPage", this.currentPage - 1);
    },
    btnClick(num) {
      if (num !== this.currentPage) {
        this.$emit("setPage", num);
      }
    },
  },
  created() {
    if (this.total) {
      this.allPage = Math.ceil(this.total / this.pageSize);
    }
  },
  watch: {
    total(val) {
      console.log(val);
      let total = val || 0;
      this.allPage = Math.ceil(total / this.pageSize);
    },
  },
  computed: {
    indexs() {
      var left = 1;
      var right = this.allPage;
      var arr = [];
      var index = parseInt(this.showIndex / 2);
      var middleIndex = index + 1;
      if (this.allPage >= this.showIndex) {
        if (
          this.currentPage > middleIndex &&
          this.currentPage < this.allPage - index
        ) {
          left = this.currentPage - index;
          right = this.currentPage + index;
        } else if (this.currentPage <= middleIndex) {
          left = 1;
          right = this.showIndex;
        } else {
          left = this.allPage - (this.showIndex - 1);
          right = this.allPage;
        }
      }
      while (left <= right) {
        arr.push(left);
        left++;
      }
      return arr;
    },
  },
};
</script>

<style scoped lang="less">
.pagination {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    li {
      padding: 0px 2px;
      span {
        display: block;
        height: 100%;
        text-align: center;
        color: #0cb1f3;
        &.disabled {
          color: #d8d8d8;
        }
      }
      &.item {
        min-width: 0.6rem;
        span {
          padding: 0px 3px;
          color: #413134;
        }
        &.active {
          span {
            color: #0cb1f3;
          }
        }
      }
    }
  }
}
</style>